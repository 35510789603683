import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IPerformance } from 'types/schedule';
import Button from '../../components/ui/Button';

interface Props {
	items: IPerformance[];
	onSetCurrentItem: (id: string) => void;
}

function PerformanceListPage({ items, onSetCurrentItem }: Props) {

	return (
		<>
			<div className="schedule__articles">
				<div className="inner">
					{items && items.map((x, i) => {
						return (
							<article key={x.id} className="performance">
								<div className="performance__inner" onMouseEnter={() => onSetCurrentItem(x.id)}>
									<h3 className="performance__title">{x.title}</h3>
									{x.dates && x.dates.map((y: any, i: any) => (
										<div key={i} className="performance__slot">
											{y.date_time && <p className="performance__date">{y.date_time}</p>}
											{y.location && <address className="performance__address">
												<FaMapMarkerAlt />
												{y.location}
											</address>}
										</div>
									))}
									{x.url && (
										<div className="performance__button-wrapper">
											<Button opensNewTab className="button--white" url={x.url}>Book tickets</Button>
										</div>
									)}
								</div>
							</article>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default PerformanceListPage;
