import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import moment from 'moment';
import PerformanceListPage from '../template-parts/PerformanceListPage';
import { IPerformance } from 'types/schedule';
import Button from '../ui/Button';
import FooterPage from '../layout/FooterPage';

interface Props {
	backgroundImgSrc: string;
}

function SchedulePage({ backgroundImgSrc, transitionStatus, enter, exit }: any) {
	const { scroll } = useLocomotiveScroll();
	const [items, setItems] = useState<IPerformance[]>([]);
	const [currentFilter, setCurrentFilter] = useState<'upcoming' | 'past'>('upcoming');
	const [currentItem, setCurrentItem] = useState<IPerformance[]>([]);

	useEffect(() => {
		document.body.classList.remove('bg-white', 'bg-black', 'bg-brand-one', 'bg-home');
	}, []);

	useEffect(() => {
		if (scroll === null) {
			return;
		}

		setTimeout(() => {
			scroll.destroy();
			scroll.init();
		}, 1000);
	}, [scroll]);

	useEffect(() => {
		const date = moment().format();

		const filteredData = data.allWordpressWpPerformance.edges.filter((x: any) => {
			const itemDate = moment(x.node.acf.dates[0].date_time).format();

			if (currentFilter === 'upcoming') {
				return itemDate > date;
			}

			return itemDate < date;
		});

		const sortedData = filteredData.sort((a: any, b: any) => {
			if (currentFilter === 'past') {
				return (new Date(b.node.acf.dates[0].date_time) as any) - (new Date(a.node.acf.dates[0].date_time) as any);
			}
			return (new Date(a.node.acf.dates[0].date_time) as any) - (new Date(b.node.acf.dates[0].date_time) as any);
		});

		setItems(
			sortedData.map((x: any) => {
				const { node } = x;
				return (
					{
						id: node.id,
						title: node.title,
						url: `${node.acf.url}`,
						dateTime: node.acf.dates[0].date_time,
						dates: node.acf.dates,
						location: node.acf.dates[0].location,
						imgSrc: node.featured_media ? node.featured_media.localFile.childImageSharp.fluid.src : undefined
					}
				);
			}
			));
	}, [currentFilter]);

	const data = useStaticQuery(graphql`
        {
            allWordpressWpPerformance {
                edges {
                    node {
                        id
                        title
                        slug
                        acf {
                            dates {
                                date_time
								location
                            }
							url
                        }
						featured_media {
							localFile {
								childImageSharp {
									fluid(maxWidth: 2880, quality: 100) {
										src
									}
								}
							}
						}
                    }
                }
            }
        }
    `);

	return (
		<>
			<section id="schedule" className="schedule section--fluid schedule--page" data-scroll-section data-bg-colour="black">
				<div className="section-title" data-scroll data-scroll-speed="-1" data-scroll-direction="vertical">
					<h2 className={`animate ${transitionStatus || ''}`}>Schedule</h2>
				</div>
				<div className="schedule__bg-wrapper" id="bg">
					<div
						className="schedule__bg"
						style={{ backgroundImage: `url('${backgroundImgSrc}')` }}
						data-scroll
						data-scroll-sticky
						data-scroll-target="#bg"
						data-scroll-speed="1"
					/>
				</div>
				<div className="container container--fluid">
					<div className={`animate schedule__list${transitionStatus ? ` schedule__list--${transitionStatus}` : ''}`} id="list">
						<div data-scroll data-scroll-sticky data-scroll-target="#list" data-scroll-speed="1" className={`animate schedule__page-filters${transitionStatus ? ` schedule__page-filters--${transitionStatus}` : ''}`}>
							<ul>
								<li className={currentFilter === 'upcoming' ? 'active' : ''}>
									<Button onClick={() => setCurrentFilter('upcoming')} className="button--white">Upcoming concerts</Button>
								</li>
								<li className={currentFilter === 'past' ? 'active' : ''}>
									<Button onClick={() => setCurrentFilter('past')} className="button--white">Past concerts</Button>
								</li>
							</ul>
						</div>
						<PerformanceListPage items={items} onSetCurrentItem={(x) => setCurrentItem(() => items.filter((y: IPerformance) => y.id === x))} />
					</div>
				</div>
			</section>
			<FooterPage />
		</>
	);
}

export default SchedulePage;
